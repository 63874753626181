<template>
    <div id="main-wrapper">
        <div v-if="success">
            <div class="mfp-bg animated zoomIn mfp-ready"></div>
            <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor animated zoomIn mfp-ready" tabindex="-1" style="overflow: hidden auto;">
                <div class="mfp-container mfp-s-ready mfp-inline-holder">
                    <div class="mfp-content">
                        <div id="upload-confirm" class="white-popup-block popup-content popup-small">
                            <div class="content t-white t-left">
                                <p>Your payment method has been added successfully. You can track it under Open Tickets.</p>
                            </div>
                            <div class="btn-holder t-center">
                                <a href="#" class="btn btn-lblue close-popup" @click.prevent="success = false;gotoTickets()">OK </a>
                            </div>
                            <button title="Close (Esc)" type="button" class="mfp-close" @click.prevent="success = false;gotoTickets()">×</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-container profile-page personal-details request-call support-page">
            <div class="page-wrap">
                <div class="main-content f-left">

                    <div class="form-holder" v-if="this.store.showOTP">
                  <div class="form-group">
                    <h4>
                      We've sent a OTP to your registered email. Please enter it
                      to verify support ticket request.
                    </h4>
                    <Form class="max-400" @submit="confirmSupportTicket" v-if="!success">
                      <ul class="clearfix">
                        <li class="">
                          <label for="">OTP <span style="color: #d43737">*</span></label>
                          <Field
                            class="form-control"
                            name="otp"
                            rules="required|numeric|min:6|max:6"
                            v-model="otp"
                          />
                          <ErrorMessage name="otp" />
                        </li>
                      </ul>
                      <div class="btn-holder t-center">
                        <button
                          type="submit"
                          class="btn btn-blue popup-link-holder"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
                    <div class="main-content f-left" v-else>
                        <div class="title-holder wow fadeInLeft">
                            <h4>Add Payment Methods</h4>
                        </div>
                        <div class="content-holder wow fadeInLeft">
                            <div class="row-sec">
                                <div class="d-flex">
                                    <label for="">Select Payment Method</label>
                                    <select class="form-control" v-model="payment">
                                        <option value="" disabled>Select Method</option>
                                        <option value="bank">Bank</option>
                                        <option value="card">Credit Card</option>
                                        <option value="crypto">Crypto</option>
                                    </select>
                                </div>
                                <div class="d-flex" v-if="payment == 'crypto'">
                                    <label for="">Select Crypto Currency</label>
                                    <select class="form-control" v-model="cryptopayment" >
                                        <option value="" disabled>Select Crypto</option>
                                        <option value="BTC">BTC</option>
                                        <option value="ETH">ETH</option>
                                        <option value="USDT">USDT</option>
                                        <option value="USDC">ETH</option>
                                    </select>
                                </div>
                                <!-- Bank details -->
                                <div class="form-holder" v-if="payment == 'bank'">
                                    <div class="d-flex justify-content-left" style="padding-top: 20px;">
                                        <label><strong>Add Your Bank Details</strong></label>
                                    </div>
                                    <Form @submit="bankdetails()">
                                        <ul class="clearfix">
                                            <li class="">
                                                <label for="">Bank Name <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" name="Bank" rules="required" v-model="form.bank" />
                                                <ErrorMessage name="Bank" />
                                            </li>
                                            <li class="">
                                                <label for="">Account Name <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" name="Account Name" rules="required" v-model="form.accname" />
                                                <ErrorMessage name="Account Name" />
                                            </li>
                                            <li class="">
                                                <label for="">Account Number <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" name="Account Number" rules="required" v-model="form.acc" />
                                                <ErrorMessage name="Account Number" />
                                            </li>
                                            <li class="">
                                                <label for="">Country <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" as="select" name="Country" rules="required" v-model="form.country" >
                                                    <option value="" disabled>Select country</option>
                                                    <option
                                                      v-for="country in store.countries"
                                                      :value="country.iso2"
                                                      :key="country.iso2"
                                                    >
                                                      {{ country.name }}
                                                    </option>
                                                </Field>
                                                <ErrorMessage name="Country" />
                                            </li>
                                            <li class="">
                                                <label for="">IBAN <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" name="IBAN" :rules="(!noIban) ? 'required' : ''" v-model="form.iban" :disabled="(noIban) ? true : false"/>
                                                <label class="customCheckBox">My Bank Doesn’t issue IBAN
                                                    <input type="checkbox" style="width: 20px;" v-model="noIban"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                                <ErrorMessage name="IBAN" />
                                            </li>
                                            <li class="">
                                                <label for="">Swift/BIC/Sort Code <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" name="Code" rules="required" v-model="form.code" />
                                                <ErrorMessage name="Code" />
                                            </li>
                                            <li class="">
                                                <label for="">Bank Address <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" name="Address" rules="required" v-model="form.address" />
                                                <ErrorMessage name="Address" />
                                            </li>
                                            <li class="">
                                                <label for="">Upload Your Bank Statement <span style="color: #d43737;">*</span></label>
                                                <div class="file-upload">
                                                    <div class="file-upload-select">
                                                        <Field type="file" name="File" accept="image/*,application/pdf" @change="setImage($event,'bank')" class="file-upload-input"  id="file-upload" rules="required" />
                                                        <div class="file-select-name">
                                                            {{ bankattachment.name }}
                                                        </div>
                                                        <div class="file-select-button">
                                                            <img src="assets/images/file-upload-icon.png" alt="" />
                                                        </div>
                                                    
                                                        
                                                    </div>
                                                </div>
                                                <!-- <span v-if="error.bankerror" style="color: #ff0000;">{{error.bankerror }}</span> -->
                                                <ErrorMessage name="File" />
                                                <div class="note">
                                                    <p>
                                                        <span style="color: #f14c52;">*</span> Size of the document should not be more than 1MB <br />
                                                        <span style="color: #f14c52;">*</span> File format (jpeg or pdf) only <br />
                                                    </p>
                                                    <p><strong>Note : </strong>In case of any error while doing any account operation, please attach the screenshot also while creating ticket</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="btn-holder t-center">
                                            <button type="submit" class="btn btn-blue popup-link-holder">
                                                Create Support Request
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                                <!-- Card Details -->
                                <div class="form-holder" v-if="payment == 'card'">
                                    <div class="d-flex justify-content-left" style="padding-top: 20px;">
                                        <label><strong>Add Your Card Details</strong></label>
                                    </div>
                                    <Form @submit="creditdetails()">
                                        <ul class="clearfix">
                                            <li class="">
                                                <label for="">Creadit Card Number <span style="color: #d43737;">*</span></label>
                                                <div class="row">
                                                    <div class="col-12 col-md-5">
                                                        <Field class="form-control" name="First 4 digits" rules="required" v-model="cardform.firstnumber" placeholder="First 4 Digits" />
                                                        <ErrorMessage name="First 4 digits" />
                                                    </div>
                                                    <div class="col-12 col-md-1">
                                                        <span>***</span>
                                                    </div>
                                                    <div class="col-12 col-md-5">
                                                        <Field class="form-control" name="Last 4 digits" rules="required" v-model="cardform.lastnumber" placeholder="Last 4 Digits" />
                                                        <ErrorMessage name="Last 4 digits" />
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="">
                                                <div class="row">
                                                    <div class="col-12 col-md-5">
                                                        <Field id="month" as="select" name="Month" class="selectExpiryDate form-control" v-model="cardform.month" rules="required">
                                                            <option value="" disabled>Month</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                        </Field>
                                                        <ErrorMessage name="Month" />
                                                    </div>
                                                    <div class="col-12 col-md-1">
                                                        <span>/</span>
                                                    </div>
                                                    <div class="col-12 col-md-5">
                                                        <Field id="year" as="select" name="Year" class="selectExpiryDate form-control" v-model="cardform.year" rules="required">
                                                            <option value="" disabled>
                                                                Year
                                                            </option>
                                                            <option value="2020">2020</option>
                                                            <option value="2021">2021</option>
                                                            <option value="2022">2022</option>
                                                            <option value="2023">2023</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                            <option value="2027">2027</option>
                                                            <option value="2028">2028</option>
                                                            <option value="2029">2029</option>
                                                            <option value="2030">2030</option>
                                                        </Field>
                                                        <ErrorMessage name="Year" />
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="">
                                                <label for="">CVV</label>
                                                <span>***</span>
                                            </li>
                                            <li class="">
                                                <p>We require the following for the card verification -</p>
                                                <p>Color copy of the front side of the card, showing: full name, expiration date, Bank name/logo, first 4 and last 4 digits of the credit card number hiding the middle 8 digits.</p>
                                            </li>
                                            <li class="">
                                                <label for="">Upload Supporting Document <span style="color: #d43737;">*</span></label>
                                                <div class="file-upload">
                                                    <div class="file-upload-select">
                                                        <Field type="file" name="Document" accept="image/*,application/pdf" @change="setImage($event,'card1')" class="file-upload-input" id="file-upload" rules="required" />
                                                        <div class="file-select-name">
                                                            {{ cardFirstDoc.name }}
                                                        </div>
                                                        <div class="file-select-button">
                                                            <img src="assets/images/file-upload-icon.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="Document" />
                                            </li>
                                            <li class="">
                                                <p>Color copy of the back side of the card hiding the CVV security code. The back copy should shown the sign of the holder</p>
                                            </li>
                                            <li class="">
                                                <label for="">Upload Supporting Document <span style="color: #d43737;">*</span></label>
                                                <div class="file-upload">
                                                    <div class="file-upload-select">
                                                        <Field type="file" name="document" accept="image/*,application/pdf" @change="setImage($event,'card2')" class="file-upload-input" id="file-upload" rules="required"/>
                                                        <div class="file-select-name">
                                                            {{ cardSecDoc.name }}
                                                        </div>
                                                        <div class="file-select-button">
                                                            <img src="assets/images/file-upload-icon.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="document" />
                                            </li>
                                        </ul>
                                        <div class="btn-holder t-center">
                                            <button type="submit" class="btn btn-blue popup-link-holder">
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                                <!-- Crypto Details -->
                                <div class="form-holder" v-if="payment == 'crypto' && cryptopayment != ''">
                                    <div class="d-flex justify-content-left" style="padding-top: 20px;">
                                        <label><strong>Add Selected Crypto Address</strong></label>
                                    </div>
                                    <Form @submit="cryptodetails()">
                                        <ul class="clearfix">
                                            <li class="">
                                                <label for="">{{cryptopayment}} Address <span style="color: #d43737;">*</span></label>
                                                <Field class="form-control" name="address" rules="required" v-model="crypto.address" />
                                                <ErrorMessage name="address" />
                                            </li>
                                        </ul>
                                        <div class="btn-holder t-center">
                                            <button type="submit" class="btn btn-blue popup-link-holder">
                                                Create Support Request
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div class="img-holder t-center wow fadeInRight va-top">
                            <img src="assets/images/supp-img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from "@/store";
    import { Form, Field, ErrorMessage } from "vee-validate";

    export default {
        setup() {
            const store = useMainStore();
            return { store };
        },
        data() {
            return {
                cryptopayment: "",
                payment: "",
                otp: "",
                userNote: null,
                success: false,
                crypto:{
                    address: ""
                },
                form: {
                    bank: "",
                    acc: "",
                    accname: "",
                    country: "",
                    iban: "",
                    address: "",
                    code : ""
                },
                noIban : false,
                cardform: {
                    firstnumber: "",
                    lastnumber: "",
                    month: "",
                    year: "",
                    cvv: "",
                },
                bankattachment: {},
                cardFirstDoc : {},
                cardSecDoc : {}

            };
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        methods: {
            gotoTickets(){
                this.$router.push('/open-tickets')
            },
			setImage(e,from) {
                if(from == 'bank'){
                    this.bankattachment = e.target.files[0];
                }else if(from == 'card1'){
                    this.cardFirstDoc = e.target.files[0];
                }else if(from == 'card2'){
                    this.cardSecDoc = e.target.files[0];
                }
			},
            reset(){
                this.payment = ""
                this.form =  {
                    bank: "",
                    acc: "",
                    accname: "",
                    country: "",
                    iban: "",
                    address: "",
                    code : ""
                },
                this.bankattachment =  {},
                this.cardFirstDoc = {},
                this.cardSecDoc = {},
                this.noIban = false,
                this.cardform = {
                    firstnumber: "",
                    lastnumber: "",
                    month: "",
                    year: "",
                    cvv: "",
                },
                this.otp = ""

            },
            cryptodetails(){
                let params={
                    'address': this.crypto.address,
                    'currency': this.cryptopayment,
                }
                // let formData = new FormData();
                // formData.append("address", this.crypto.address);
                // formData.append("currency", this.cryptopayment);
                this.store.addCryptoMethod(params, true,this);
                this.$router.push({ path: '/manage-funds', query: { fundType: 'Withdrawals' }})
            },
            bankdetails() {
                let formData = new FormData();
                if(this.bankattachment.name){
                    formData.append("document", this.bankattachment);
                }
                formData.append("bank_name", this.form.bank);
                formData.append("holder_name", this.form.accname);
                formData.append("account_no", this.form.acc);
                formData.append("country", this.form.country);
                formData.append("sort_code", this.form.code);
                formData.append("bank_address", this.form.address);
                formData.append("reason", '16');
                formData.append("reason_text",'Add payment Method');
                formData.append("title",'93');
                formData.append("title_text",'Add New Bank');
                let data = `Please Change my Bank Information as per following details <br> Account Number : ${this.form.acc}<br> Holder Name : ${this.form.accname} <br> Bank Name : ${this.form.bank} <br> Bank Code : ${this.form.country} <br> Sort Code : ${this.form.code} <br> Bank Address : ${this.form.address}`;
                formData.append("text",data);
                this.store.createSupportTicket(formData, true,this);
                
            },
            creditdetails() {
                let formData = new FormData();
                if(this.cardFirstDoc.name){
                    formData.append("document", this.cardFirstDoc);
                }
                if(this.cardSecDoc.name){
                    formData.append("document", this.cardSecDoc);
                }
                formData.append("first_digit_card", this.cardform.firstnumber);
                formData.append("last_digit_card", this.cardform.lastnumber);
                formData.append("month", this.cardform.month);
                formData.append("year", this.cardform.year);
                formData.append("method", '5');
                formData.append("subject", 'Add payment Method');
                formData.append("reason", '16');
                formData.append("reason_text",'Add payment Method');
                formData.append("title",'107');
                formData.append("title_text",'Credit Card');
                let data = `Please Add Credit Card Payment Method as per following details <br> Credit CardId: ${this.cardform.firstnumber} **** ****  ${this.cardform.lastnumber} <br> Expiry Date : ${this.cardform.month}`;
                formData.append("text",data);
                this.store.createSupportTicket(formData, true,this);
            },
            confirmSupportTicket() {
              let formData = {
                otp: this.otp,
                trans_id: this.store.trans_id,
                from : 'add_payment'
              };
              this.store.confirmSupportTicket(formData, true, this);
            },
        },
    };
</script>
